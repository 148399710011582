import storeExport from '../clientStore';

const inCart = (product, cart, carForCart, userSelectedCar) => {
  // debugger;
  // console.log({carForCart, userSelectedCar});
  if ((carForCart && carForCart.fuel && carForCart.fuel.car_type_id)
  && (userSelectedCar && userSelectedCar.fuel && userSelectedCar.fuel.car_type_id)
  && (carForCart.fuel.car_type_id !== userSelectedCar.fuel.car_type_id)
  ) return false;

  const cartItems = cart?.all_services;

  const existence = (cartItems
    ? cartItems.reduce((a, item) => {
      let parentId = null;
      // if (item.package_details && item.package_details.includes(product.id)) {
      //   return { exists: true, indirect: true };
      // }
      if (item.sub_category_id || (item.sub_category_id === 0)) parentId = item.sub_category_id;
      // if (item.retail_service_type_id
      //   || (item.retail_service_type_id === 0)) parentId = item.retail_service_type_id;

      let itemId = null;
      if (item.id) itemId = item.id;
      
      if(product?.type === 'tyre'){
        // extra check to verify if the tyre item in cart is present in the "extra_tyre_options" list
        // if yes then spread it on the top level of product and mark it as present by returning true
        return (product?.extra_tyre_options?.reduce((acc, val) => {
          if(val.id === itemId){
            Object.keys(val).forEach(key =>  {
              product[key] = val[key];
            });
          }
         return acc || (val.id === itemId);
       }, false)) ? true : a;
     }

      /* return (itemId === product.id
      && (parentId === product.sub_category_id) ? true : a); */
      return ((itemId === product.id)? true : a);
    }, false)
    : false);
  if (existence && existence.exists && existence.indirect) {
    return { exists: true, indirect: true };
  }
  if (existence && !existence.exists) {
    return { exists: true, indirect: false };
  }
  if (!existence) {
    return { exists: false, indirect: false };
  }
};

export const priceCalculator = (service) => {
  let strikedPrice = null;
  let applicablePrice = null;

  if (service) {
    if (service.strike_through_price) strikedPrice = service.strike_through_price;
    if (service.price_details && service.price_details.strike_through_price) strikedPrice = service.price_details.strike_through_price;
    if (service.strikethrough) strikedPrice = service.strikethrough;
    if (service.add_on_price_details && service.add_on_price_details[0].strike_through_price) strikedPrice = service.add_on_price_details[0].strike_through_price;
  }

  if (service) {
    if (service.material_cost) applicablePrice = service.material_cost;
    if (!service.material_cost && service.price_details && service.price_details.material_cost) applicablePrice = service.price_details.material_cost;
    if (service.add_on_price_details && service.add_on_price_details[0].material_cost) applicablePrice = service.add_on_price_details[0].material_cost;

    if (service.labour_cost) {
      if (applicablePrice !== null) applicablePrice += service.labour_cost;
      else applicablePrice = service.labour_cost;
    }

    if (
      service.price_details
      && service.price_details.labour_cost
      && !service.labour_cost
    ) {
      if (applicablePrice !== null) applicablePrice += service.price_details.labour_cost;
      else applicablePrice = service.price_details.labour_cost;
    }

    if (
      service.add_on_price_details
      && service.add_on_price_details[0].labour_cost
      && !applicablePrice
    ) {
      if (applicablePrice !== null) applicablePrice += service.add_on_price_details[0].labour_cost;
      else applicablePrice = service.add_on_price_details[0].labour_cost;
    }
  }
  if (service) {
    if (service.total_amount) {
      if (applicablePrice !== null) applicablePrice += service.total_amount;
      else applicablePrice = service.total_amount;
    }
    if (service.total_amount === 0) {
      applicablePrice = 0;
    }
    if (service.oil_brand && service.oil_brand.length > 0) {
      if (service.oil_brands && service.oil_brands.length > 0) {
        const priceOfOil = service.oil_brands.reduce(
          (a, oil) => (oil.name === service.oil_brand ? oil.extra_price : a), 0,
        );
        applicablePrice += priceOfOil;
      }
    }
    if (applicablePrice && !strikedPrice) {
      strikedPrice = applicablePrice + 1000;
    }
    if ((strikedPrice)) {
      strikedPrice = parseInt(strikedPrice, 10);
    }
    if ((applicablePrice)) {
      applicablePrice = parseInt(applicablePrice, 10);
    }
    // if (applicablePrice && !strikedPrice) {
    //   strikedPrice = applicablePrice + 1000;
    // }

    if (applicablePrice && service.count && service.individualPrice) {
      applicablePrice = service.individualPrice * service.count;
    }

    return { strikedPrice, applicablePrice };
  }
};

export const isValidPrice = (val) => !(val === undefined || val === null || val === false);

export const priceCalculatorV3 = (service) => {
  let strikedPrice = null;
  let applicablePrice = null;
  let pricesResolved = false;
  if (!pricesResolved && service.package_details
    && service.package_details[0]
    && isValidPrice(service.package_details[0].total)) {
    const packageDetails = service.package_details[0];
    if (isValidPrice(packageDetails.total)) {
      applicablePrice = packageDetails.total;
    }
    if (isValidPrice(packageDetails.strike_through_price)) {
      strikedPrice = packageDetails.strike_through_price;
    }
    pricesResolved = true;
  }

  if (!pricesResolved && service.price_details
    && service.price_details[0]
    && isValidPrice(service.price_details[0].total)) {
    const priceDetails = service.price_details[0];
    if (isValidPrice(priceDetails.total)) {
      applicablePrice = priceDetails.total;
    }
    if (isValidPrice(priceDetails.strike_through_price)) {
      strikedPrice = priceDetails.strike_through_price;
    }
    pricesResolved = true;
  }

  if (!pricesResolved && service.price) {
    // for tyres and batteries
    const priceDetails = service;
    if (isValidPrice(priceDetails.price)) {
      applicablePrice = priceDetails.price;
    }
    if (isValidPrice(priceDetails.strike_through_price)) {
      strikedPrice = priceDetails.strike_through_price;
    }
  }
  return { strikedPrice, applicablePrice };
};

export const getCartTotal = (cart, withCoupon = false) => {
  let discount = 0;
  if (withCoupon) {
    const appState = storeExport.store.getState();
    const { cart: cartFromRedux } = appState;
    const { coupon, coupon_discount } = (cartFromRedux || {});
    if (cart && coupon && coupon_discount) {
      discount = coupon_discount;
    }
  }

  const cartItems = (cart && cart?.all_services) ? cart?.all_services : [];

  const total = cartItems.reduce((a, service) => {
    const currentServicePrice = priceCalculatorV3(service);
    return ((currentServicePrice.applicablePrice
      ? parseInt(a + currentServicePrice.applicablePrice, 10)
      : a));
  }, 0);
  return (total - discount);
};

export const accessoriesExist = (store) => {
  if (!store) {
    console.error('No store provided.');
    return;
  }
  const props = store;
  return !!(props.userAccessoriesCart && props.userAccessoriesCart?.order_lines
    && props.userAccessoriesCart?.order_lines?.length > 0);
};


export const servicesExist = (store) => {
  if (!store) {
    console.error('Store not provided');
    return;
  }
  return !!(store.cart && store.cart?.all_services && store.cart?.all_services.length);
};

export const getCartTotalWithAccessories = (store) => {
  const props = store;
  let totalPrice = 0;
  let discountPrice = 0;
  if (props.userAccessoriesCart && props.userAccessoriesCart?.order_lines) {
    totalPrice = props.userAccessoriesCart?.total_before_discount;
    discountPrice = props.userAccessoriesCart?.total;
  }
  const cartItems = (props.cart && props.cart?.all_services) ? props.cart?.all_services : [];

  if (cartItems.length) {
    totalPrice += getCartTotal(props.cart);
    discountPrice += getCartTotal(props.cart, true);
    if(props.cart && props.cart?.gom_discount > 0) {
      discountPrice -= props.cart?.gom_discount;
    }
    if(props.cart && props.cart?.online_payment_discount > 0) {
      discountPrice -= props.cart?.online_payment_discount;
    }
    if(props.cart && props.cart?.additional_amount > 0) {
      discountPrice += props.cart?.additional_amount;
    }
    if(props.orderDateTime && props.orderDateTime.amount && totalPrice > 0) {
      discountPrice += props.orderDateTime?.amount;
    }
  }

  return ({
    totalPrice, discountPrice,
  });
};

export const getCartTotalWithSlot = (store) => {
  const props = store;
  let totalPrice = 0;
  let discountPrice = 0;
  if (props.userAccessoriesCart && props.userAccessoriesCart?.order_lines) {
    totalPrice = props.userAccessoriesCart?.total_before_discount;
    discountPrice = props.userAccessoriesCart?.total;
  }
  const cartItems = (props.cart && props.cart?.all_services) ? props.cart?.all_services : [];

  if (cartItems.length) {
    totalPrice += getCartTotal(props.cart);
    discountPrice += getCartTotal(props.cart, true);
    if(props.cart && props.cart?.additional_amount > 0) {
      discountPrice += props.cart?.additional_amount;
    }
  }

  return ({
    totalPrice, discountPrice,
  });
};

export const getItemsCountWithAccessories = (props) => {
  let count = 0;
  if (props.cart && props.cart?.all_services) count += props.cart?.all_services.length;
  if (accessoriesExist(props)) {
    count += props.userAccessoriesCart?.order_lines?.length;
  }
  return count;
};

export default {
  inCart,
};
